<template>
  <div id="divYapKazanWrapper" class="container">
    <hr />
    <div class="content-layout-fixer">
      <div class="ad-wrapper-large">
        <div id="div-gpt-ad-1587046762079-0"></div>
      </div>
      <div class="toolbar">
        <div
          :class="toolbar.activeTab == 'incomplete' ? 'active-title' : ''"
          @click="setActiveTab('incomplete')"
        >
          <VueText
            :color="toolbar.activeTab == 'incomplete' ? 'currentColor' : 'grey-30'"
            sizeLevel="5"
            weightLevel="3"
            >AKTİF</VueText
          >
        </div>
        <div
          :class="toolbar.activeTab == 'completed' ? 'active-title' : ''"
          @click="setActiveTab('completed')"
        >
          <VueText
            :color="toolbar.activeTab == 'completed' ? 'currentColor' : 'grey-30'"
            sizeLevel="5"
            weightLevel="3"
            >TAMAMLANMIŞ</VueText
          >
        </div>
      </div>
    </div>
    <hr class="bottom" />
    <div class="content-layout-fixer">
      <div class="task-list-wrapper">
        <div v-if="filter.showFilter" class="filter-container">
          <div
            class="filter-menu"
            ref="filterMenu"
            v-if="filter.active == 'completed' && passiveTasks !== null"
          >
            <div class="filter-grid">
              <div
                v-for="(g, index) in filter.filters"
                class="filter-group"
                :key="'filter_' + index"
              >
                <div
                  class="filter-header"
                  :text="index | filterGroupText"
                  :key="'item_header_' + index"
                >
                  {{ index | filterGroupText }}
                </div>
                <div class="filter-list">
                  <VueListItem
                    :text="item.Value"
                    @click="selectFilterItem(item, index, i)"
                    v-for="(item, i) in g"
                    :key="index + '_' + i + '_' + renderKey"
                    :icon="getFilterIcon(item.Selected)"
                    :contentAlignment="constants.flexAlignment.between"
                  ></VueListItem>
                </div>
              </div>
            </div>
            <button class="btn-more" @click="applyFilter">
              <VueText sizeLevel="4" weightLevel="4">Filtrele</VueText>
            </button>
          </div>
        </div>

        <div v-if="filter.active == 'incomplete' && activeTasks !== null">
          <div class="header" v-if="activeTasks.length">
            <VueText sizeLevel="9" weightLevel="3">{{ activeHeader }}</VueText>
            <div
              v-if="toolbar.activeTab == 'completed'"
              class="filter-button"
              @click="toggleFilter"
            >
              <VueIcon
                :iconName="icon.filter.name"
                :iconColor="'#79838e'"
                :width="icon.filter.width"
                :height="icon.filter.height"
              ></VueIcon>
            </div>
          </div>
          <div v-if="activeTasks.length">
            <ul is="transition-group" name="rotate">
              <li v-for="task in activeTasks" :key="task.taskUserId">
                <div class="task">
                  <div class="task-container">
                    <div class="title">
                      <div class="task-images">
                        <BrandLogo
                          v-for="partner in task.partners"
                          :key="partner.id"
                          class="task-image"
                          :image="partner.image"
                          :width="75"
                          :height="75"
                        />
                      </div>
                      <div class="task-name">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30"
                          >Yap Kazan Adı</VueText
                        >
                        <VueText sizeLevel="4" weightLevel="3">{{ task.title }}</VueText>
                      </div>
                    </div>

                    <div class="grid-container" :class="toolbar.activeTab">
                      <div class="grid-item">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30"
                          >Yap Kazan Tipi</VueText
                        >
                      </div>
                      <div class="grid-item">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30">Ödül</VueText>
                      </div>
                      <div class="grid-item date">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30">Başlangıç</VueText>
                        <span class="info-icon" @click="openInfoModal(task)">
                          <VueIcon
                            :iconName="icon.info.name"
                            :iconSize="{ width: icon.info.width, height: icon.info.height }"
                          ></VueIcon>
                        </span>
                      </div>
                      <div class="grid-item">
                        <VueText sizeLevel="4" weightLevel="3">{{ task.description }}</VueText>
                      </div>
                      <div class="grid-item">
                        <VueText sizeLevel="4" weightLevel="3">{{ task.award }}</VueText>
                      </div>
                      <div class="grid-item date-content">
                        <div class="date-container">
                          <VueText sizeLevel="4" weightLevel="3">
                            {{ task.startDate | parseISODate }}
                          </VueText>
                        </div>
                        <div class="date-container">
                          <VueText sizeLevel="3" weightLevel="3" color="grey-30">Bitiş</VueText>
                          <VueText sizeLevel="4" weightLevel="3">
                            {{ task.endDate | parseISODate }}
                          </VueText>
                        </div>
                      </div>
                    </div>
                  </div>
                  <BrandButton
                    class="btn-start"
                    :contentAlignment="constants.flexAlignment.center"
                    @click="startTask(task)"
                    >{{ taskButtonText(task) }}</BrandButton
                  >
                </div>
              </li>
            </ul>
          </div>
          <div class="empty-container" v-else>
            <img src="~@/assets/icons/checkGradient.svg" alt="check" />
            <VueText sizeLevel="11" weightLevel="3">Aktif Yap Kazan'ın Bulunmamaktadır</VueText>
            <BrandButton
              class="submit-button"
              :size="sizes.xxLarge"
              :contentAlignment="constants.flexAlignment.center"
              type="submit"
              @click.prevent="sendDataLayerEvent() || setActiveTab('completed')"
              >TAMAMLANMIŞ YAP KAZAN'LARI GÖR</BrandButton
            >
          </div>
        </div>

        <div v-if="filter.active == 'completed' && passiveTasks !== null">
          <div class="header" v-if="passiveTasks.length">
            <VueText sizeLevel="9" weightLevel="3">{{ activeHeader }}</VueText>
            <div
              v-if="toolbar.activeTab == 'completed'"
              class="filter-button"
              @click="toggleFilter"
            >
              <VueIcon
                :iconName="icon.filter.name"
                :iconColor="'#79838e'"
                :width="icon.filter.width"
                :height="icon.filter.height"
              ></VueIcon>
            </div>
          </div>
          <div v-if="passiveTasks.length">
            <ul is="transition-group" name="flip">
              <li v-for="task in passiveTasks" :key="task.taskUserId">
                <div class="task">
                  <div class="task-container">
                    <div class="title">
                      <div class="task-images">
                        <BrandLogo
                          v-for="partner in task.partners"
                          :key="partner.id"
                          class="task-image"
                          :image="partner.image"
                          :width="75"
                          :height="75"
                        />
                      </div>
                      <div class="task-name">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30"
                          >Yap Kazan Adı</VueText
                        >
                        <VueText sizeLevel="4" weightLevel="3">{{ task.title }}</VueText>
                      </div>
                    </div>
                    <div class="grid-container" :class="toolbar.activeTab">
                      <div class="grid-item">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30"
                          >Yap Kazan Tipi</VueText
                        >
                      </div>
                      <div class="grid-item">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30">Ödül</VueText>
                      </div>
                      <div class="grid-item">
                        <VueText sizeLevel="3" weightLevel="3" color="grey-30">Başlangıç</VueText>
                      </div>
                      <div class="grid-item">
                        <VueText sizeLevel="4" weightLevel="3">{{ task.description }}</VueText>
                      </div>
                      <div class="grid-item">
                        <VueText sizeLevel="4" weightLevel="3">{{ task.award }}</VueText>
                      </div>
                      <div class="grid-item grid-date">
                        <VueText sizeLevel="4" weightLevel="3">
                          {{ task.startDate | parseISODate }}
                        </VueText>
                        <div class="end-date">
                          <VueText as="p" sizeLevel="3" weightLevel="3" color="grey-30"
                            >Bitiş</VueText
                          >
                          <VueText as="p" sizeLevel="4" weightLevel="3">
                            {{ task.endDate | parseISODate }}
                          </VueText>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="task-status"
                    :class="[task.isSuccess ? 'status-success' : 'status-fail']"
                  >
                    <VueIcon
                      v-if="task.isSuccess"
                      :width="icon.faceSmile.width"
                      :height="icon.faceSmile.height"
                      :iconName="icon.faceSmile.name"
                      iconColor="#ffffff"
                    ></VueIcon>
                    <VueIcon
                      v-else
                      :width="icon.faceSad.width"
                      :height="icon.faceSad.height"
                      :iconName="icon.faceSad.name"
                      iconColor="#ffffff"
                    ></VueIcon>
                    <VueText color="white-100" sizeLevel="3" weightLevel="4">
                      {{ task.isSuccess | statusDisplay }}
                    </VueText>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="empty-container" v-else>
            <img src="~@/assets/icons/checkGradient.svg" alt="check" />
            <VueText sizeLevel="11" weightLevel="3"
              >Tamamlanmış Yap Kazan'ın Bulunmamaktadır</VueText
            >
            <BrandButton
              class="submit-button"
              :size="sizes.xxLarge"
              :contentAlignment="constants.flexAlignment.center"
              type="submit"
              @click.prevent="setActiveTab('incomplete')"
              >AKTİF YAP KAZAN'LARI GÖR</BrandButton
            >
          </div>
        </div>

        <div class="custom-modal" @click="closeModal" v-if="info.showModal">
          <div class="custom-modal-header">
            <a class="close-icon">
              <VueIcon iconName="IconCross" iconColor="#FFFFFF" />
            </a>
          </div>
          <div id="modal-content" class="custom-modal-content">
            <VueText class="custom-modal-title" sizeLevel="9" weightLevel="3">{{
              info.title
            }}</VueText>
            <hr />
            <div class="info-container">
              <VueText sizeLevel="6" weightLevel="3"
                >Yap Kazan'ın bitmesine {{ info.remainingDays }} gün kaldı</VueText
              >
              <div class="progressbar-wrapper">
                <div class="progressbar">
                  <div class="completed-part" :style="{ width: `${info.percentage}%` }">
                    <VueText
                      v-if="info.percentage >= 17"
                      class="percentage"
                      sizeLevel="6"
                      weightLevel="3"
                    >
                      % {{ info.percentage }}
                    </VueText>
                  </div>
                  <VueText
                    v-if="info.percentage < 17"
                    :color="'grey-40'"
                    class="percentage"
                    sizeLevel="6"
                    weightLevel="3"
                  >
                    % {{ info.percentage }}
                  </VueText>
                </div>
              </div>
              <VueText sizeLevel="6" weightLevel="2" class="info-desc">{{ info.text }}</VueText>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-container" v-if="isVisibleVirtualVisitPopup">
      <div @click="closePopupShape" class="shape" />
      <img
        @click="openVirtualVisitPopup"
        src="@/assets/images/virtual-visit.png"
        alt="virtual-visit"
        class="popup-image"
      />
    </div>
  </div>
</template>
<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import { COMPONENT_SIZES, ICON_VARIABLES } from '@/constants/component.constants';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import GameApi from '@/services/Api/gamification';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesDoAndWin from '@/router/routes/secure/RoutesDoAndWin';
import StorageHelper from '@/utils/storageHelper';
import dateUtils from '@/mixins/dateUtils';
import gtmUtils from '@/mixins/gtmUtils.js';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';

export default {
  name: 'DoAndWin',
  mixins: [dateUtils, gtmUtils],
  components: {
    BrandButton,
    VueText,
    VueListItem,
    VueIcon,
    BrandLogo,
  },
  data() {
    return {
      renderKey: 0,
      toolbar: { activeTab: 'incomplete' },
      filter: {
        showFilter: false,
        filters: [],
        active: 'incomplete',
      },
      activeTasks: null,
      passiveTasks: null,
      info: { showModal: false },
      isVisibleVirtualVisitPopup: false,
    };
  },
  filters: {
    filterGroupText(group) {
      switch (group) {
        case 'brands':
          return 'Partner';
        case 'years':
          return 'Yıl';
        case 'taskTypes':
          return 'Yap Kazan Tipi';
        case 'taskStatus':
          return 'Durum';
        default:
          return '';
      }
    },
    statusDisplay(success) {
      return success ? 'BAŞARILI' : 'BAŞARISIZ';
    },
  },
  beforeMount() {
    this.getTasks();
    //filtre datası servisten alınıp map ediliyor
    GameApi.getDoWinFilters().then(res => {
      if (res && res.data && res.data.Data) {
        this.filter.filters = Object.assign({}, res.data.Data);

        Object.keys(this.filter.filters).forEach(filterGroup => {
          this.filter.filters[filterGroup].forEach((item, key, arr) => {
            //years ile gelen data object array değil string array o yüzden ayrı map ediliyor
            let keyValuePair =
              filterGroup == 'years' ? { Key: item, Value: item.toString() } : { ...item };
            arr[key] = {
              Selected: false,
              ...keyValuePair,
            };
          });
        });
      }
    });
  },
  created() {
    this.$store.dispatch('app/setDoWinCardRefresh', true);
    window.addEventListener('click', e => {
      if (this.$refs.filterMenu) {
        if (
          this.filter.showFilter &&
          !e.target.classList.contains('filter-button') &&
          !this.$refs.filterMenu.contains(e.target)
        ) {
          if (
            e.target.classList.contains('single-list-item') ||
            e.target.classList.contains('menu-icon-wrapper') ||
            e.target.classList.contains('VueText_text_3akqb') ||
            e.target.tagName === 'svg' ||
            e.target.tagName === 'path'
          ) {
            this.filter.showFilter = true;
          } else {
            this.filter.showFilter = false;
          }
        }
      }
    });
  },
  mounted() {
    //initialize google ads
    this.pushAds('large');
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    activeHeader() {
      return this.toolbar.activeTab == 'completed'
        ? "Tamamlanmış Yap Kazan'larım"
        : "Aktif Yap Kazan'larım";
    },
    icon() {
      return {
        faceSmile: ICON_VARIABLES.faceSmile,
        faceSad: ICON_VARIABLES.faceSad,
        filter: ICON_VARIABLES.filter,
        checked: ICON_VARIABLES.checked,
        circle: ICON_VARIABLES.circle,
        info: ICON_VARIABLES.info,
      };
    },
  },
  methods: {
    taskButtonText(task) {
      if (task.taskTypeId === 3 || !task.responseTime || task.taskTypeId === 10) {
        return 'BAŞLA';
      } else {
        return 'DETAYLAR';
      }
    },
    toggleFilter() {
      this.filter.showFilter = !this.filter.showFilter;
    },
    applyFilter() {
      let filterString = '';
      Object.keys(this.filter.filters).forEach(x => {
        this.filter.filters[x].forEach(item => {
          if (item.Selected)
            filterString += `${
              x != 'taskStatus' ? x.replace(/\b[a-zA-Z]/g, match => match.toUpperCase()) : 'Status'
            }=${item.Key}&`;
        });
      });

      filterString = filterString ? filterString.slice(0, -1) : '';
      this.getTasks(filterString);
      document.documentElement.style.overflow = 'auto';
      this.filter.showFilter = false;
    },
    getFilterIcon(selected) {
      return selected ? this.icon.checked : this.icon.circle;
    },
    selectFilterItem(item, filterKey, i) {
      this.renderKey += 1;
      this.filter.filters[filterKey][i].Selected = !this.filter.filters[filterKey][i].Selected;
    },
    getTasks(filter) {
      let isActive = this.filter.active == 'incomplete';
      GameApi.getDoWinTasks(isActive, filter).then(res => {
        if (res && res.data && res.data.Data) {
          if (isActive) {
            this.activeTasks = res.data.Data.inCompletedTasks;
          } else {
            this.passiveTasks = res.data.Data.completedTasks;
          }
        }
      });
    },
    setActiveTab(tab) {
      if (tab === 'completed') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('tamamlanmis_yap_kazan');
      }
      if (this.toolbar.activeTab != tab) {
        this.toolbar.activeTab = tab;
        this.filter.active = tab;
        this.getTasks();
      }
    },
    sendDataLayerEvent() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('tamamlanmis_yap_kazan');
    },
    startTask(task) {
      if (task.taskTypeId == 10) {
        new StorageHelper({ id: 'PlayableInfo' }).set(task);
        this.$router.push({
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Playable.path}`,
        });
      } else if (task.isTaskSuperDW && !task.vvStatus) {
        this.isVisibleVirtualVisitPopup = true;
      } else {
        if (!task.referralLink) {
          const warnModalConfig = {
            isModalOpened: true,
            modalText: 'İçerik bulunamadı!',
            modalType: 'alert',
            alertType: 'warn',
            firstButtonText: 'Tamam',
          };
          this.$store.dispatch('app/setModalConfig', warnModalConfig);
        } else {
          GameApi.sendTaskResponse(task.taskUserId).then(() => {
            this.$store.dispatch('app/setDoWinCardRefresh', true);
            task.responseTime = new Date();
            if ([1, 2, 11].includes(task.taskTypeId)) {
              let x = [1, 11].includes(task.taskTypeId)
                ? 'Yoka Düşmeme Görevini'
                : 'Ürün Bulundurma Görevini';
              let y = [1, 11].includes(task.taskTypeId)
                ? 'ürününde yoka düşmeyerek'
                : 'ürününü bulundurarak';
              let z = task.productName || '';
              let locale = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              };
              let startDate = this.convertISODate(task.startDate).toLocaleDateString('tr', locale);
              let endDate = this.convertISODate(task.endDate).toLocaleDateString('tr', locale);
              let modalText = `<div>Sana özel ${x} tamamlamak için son adım! 3 hafta boyunca "${z}" ${y} "${task.award}" kazan!</div><div style="display: flex; justify-content: center; margin-top: 5px;">
        <div>
          <div>Başlangıç tarihi</div>
          <div style="text-align: left;">Bitiş tarihi</div>
        </div>
        <div>
          <div>:  ${startDate}</div>
          <div>:  ${endDate}</div>
        </div>
      </div>`;

              let detailModalConfig = {
                isModalOpened: true,
                modalType: 'info',
                noIcon: true,
                firstButtonText: 'TAMAM',
                modalText,
              };
              this.$store.dispatch('app/setModalConfig', detailModalConfig);
            } else if (task.taskTypeId === 3) {
              new StorageHelper({
                id: process.env.VUE_APP_STORAGE_TASK_TAKE_QUIZ_KEY,
              }).set({ taskUserId: task.taskUserId, testId: task.testId });
              const route = `${RoutesRoot.Secure.path}/${RoutesSecure.DoAndWin.path}/${RoutesDoAndWin.TakeQuiz.path}`;
              this.$router.push(route);
            } else if (task.taskTypeId === 4) {
              this.$store
                .dispatch('app/setFeedTaskDetail', { ...task, isFromFeed: true })
                .then(() => {
                  new StorageHelper({ id: 'FeedTaskDetail' }).set({ ...task, isFromFeed: true });
                  if (!task.referralLink) return;
                  const targetPart = task.referralLink
                    .split('/')
                    .slice(-2)
                    .join('/');

                  const currentPath = this.$route.path;
                  const newPath = currentPath.replace('/do-and-win', '/' + targetPart);
                  this.$router.push(newPath);
                });
            } else {
              if (task.referralLink) {
                window.location.href = task.referralLink;
              }
            }
          });
        }
      }
    },
    closeModal(e) {
      let modalStatus = document.getElementById('modal-content').contains(e.target);
      if (!modalStatus) {
        this.info.showModal = false;
        document.body.style.overflow = 'auto';
      }
    },
    openInfoModal(task) {
      this.info.title = task.title;
      this.info.percentage = task.taskTimeRatio;
      this.info.remainingDays = task.taskRemainingDay;
      this.info.showModal = true;
      document.body.style.overflow = 'hidden';
      this.info.text = task.infoText;
    },
    openVirtualVisitPopup() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`);
      this.isVisibleVirtualVisitPopup = false;
    },
    closePopupShape() {
      this.isVisibleVirtualVisitPopup = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';

.grid-container {
  display: grid;
  grid-template-columns: 112px auto 125px;
  width: 100%;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);

  .grid-item {
    display: flex;
    justify-content: space-between;
    border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    padding: palette-space-level('10') palette-space-level('10') 0 palette-space-level('10');
    & > svg {
      width: 17px;
      height: 17px;
    }
    &:first-child,
    &:nth-child(3n + 1) {
      border-left: none;
    }
    &:nth-child(n + 4) {
      padding-top: 0;
      padding-bottom: palette-space-level(10);
    }

    &.date-content {
      flex-direction: column;
      .date-container:not(:last-child) {
        margin-bottom: palette-space-level(10);
      }
    }
  }
}

.toolbar {
  display: flex;
  padding: palette-space-level(20);
  padding-bottom: palette-space-level(10);
  z-index: 5;
  & > div {
    margin-right: palette-space-level(40);
    cursor: pointer;
    &::after {
      content: '';
      border-radius: palette-radius('radius-11');
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -13px;
      left: 0;
      background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
      opacity: 0;
      transition: all 0.3s ease;
    }
    &.active-title {
      position: relative;
      &::after {
        opacity: 1;
      }
    }
  }
}
hr.bottom {
  position: relative;
  top: 1px;
}

.container {
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
  .task-list-wrapper {
    padding-left: palette-space-level('20');
    margin-bottom: palette-space-level('20');
    display: flex;
    flex-direction: column;
    .header {
      padding-bottom: palette-space-level('45');
      padding-top: palette-space-level('60');
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-button {
        border-radius: palette-radius('radius-2');
        border: solid 1px palette-color('grey-20');
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: palette-space-level(10);
        cursor: pointer;
        svg {
          pointer-events: none;
        }
      }
    }
    .empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 600px;
      padding: palette-space-level('50') 0;
      margin: 100px auto auto;
      img {
        margin-bottom: palette-space-level('30');
        width: 70px;
        height: 70px;
      }
      .submit-button {
        width: 320px !important;
        font-size: palette-font-size-level('5');
        font-weight: palette-font-weight-level('3');
        margin: palette-space-level('40') auto;
      }
    }
    ul {
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 321px));
      grid-row-gap: palette-space-level(30);
      grid-column-gap: palette-space-level(20);
    }

    li {
      height: 100%;
      list-style: none;
      border-radius: palette-radius('radius-2');
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      .task {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          width: 100%;
          height: 100%;
          .task-images {
            display: flex;
            min-height: 100px;
            padding: palette-space-level(10);
            .task-image {
              margin-right: palette-space-level(10);
            }
          }
          .task-name {
            border-top: 1px solid rgba(180, 194, 211, 0.5);
            padding: palette-space-level(10);
          }
        }
        .task-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .task-status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: palette-space-level(20);
          border-radius: 0 0 palette-radius('radius-2') palette-radius('radius-2');
          p {
            line-height: 16px;
            padding-left: palette-space-level(5);
          }
        }
        .btn-start {
          border-radius: 0 0 palette-radius('radius-2') palette-radius('radius-2');
          height: palette-space-level(50);
          &:after {
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
          }
        }
      }
    }
  }
  .status-success {
    @include bg-linear-gradient(
      86deg,
      palette-color-level('green', '20'),
      palette-color-level('green', '30')
    );
  }
  .status-fail {
    background: palette-color('red-10');
  }
}
.filter-container {
  position: relative;
  width: 100%;
  z-index: 999;

  .filter-menu {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    border: 1px solid palette-color('grey-20');
    border-radius: palette-radius-level(4);
    background-color: palette-color('white-100');
    .filter-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-row-gap: 75px;
      .filter-group {
        margin-right: 80px;
        .filter-header {
          font-size: 18px;
          font-weight: 500;
          margin-right: palette-space-level(20);
          padding: palette-space-level(30) palette-space-level(30) palette-space-level(20)
            palette-space-level(30);
        }
        .filter-list {
          height: 210px;
          overflow-y: auto;
          .single-list-item {
            margin-right: palette-space-level(20);
            padding: palette-space-level(20) palette-space-level(10) palette-space-level(20)
              palette-space-level(30);
            font-size: 24px;
            &:first-child {
              border-top: 0.5px solid rgba(palette-color('grey-20'), 0.6);
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      width: 8px;
      border-radius: 5.5px;
      background-color: palette-color('grey-10');
    }
    ::-webkit-scrollbar-thumb {
      width: 8px;
      border-radius: 5.5px;
      background-color: palette-color('grey-40');
    }
    .btn-more {
      display: block;
      margin: palette-space-level(60) auto palette-space-level(30) auto;
      border: 1px solid palette-color-level('grey', '20');
      align-self: center;
      background-color: palette-color-level('white', '100');
      border-radius: palette-radius-level(15);
      padding: palette-space-level(20) palette-space-level(50);
    }
  }
}
.info-icon {
  cursor: pointer;
}
// Global modal yapıldığında güncellenecek
.custom-modal {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9998;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.8);
  .custom-modal-header {
    .close-icon {
      position: absolute;
      z-index: 9999;
      right: 28px;
      top: 28px;
      cursor: pointer;
    }
  }
  .custom-modal-content {
    position: relative;
    display: block;
    width: 500px;
    background: #ffffff;
    margin: 40vh auto 0;
    transform: translateY(-50%);
    border-radius: palette-radius-level('2');

    .custom-modal-title {
      padding: palette-space-level('30');
      text-align: center;
    }
    hr {
      border-top: 0.5px solid transparentize(palette-color-level('grey', 20), 0.5);
    }
    .info-container {
      text-align: center;
      padding: palette-space-level('30');
      .progressbar-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: palette-space-level('20') 0;

        .progressbar {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 100%;
          height: palette-space-level('60');
          background-color: #dddddd;
          border-radius: palette-radius-level('15');
          overflow: hidden;
          .completed-part {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
            border-radius: palette-radius-level('15');
            display: flex;
            align-items: center;
            justify-content: center;
            .percentage {
              color: palette-color-level('white', '100');
            }
          }
        }
      }
      .info-desc {
        text-align: center;
        line-height: palette-font-size('font-size-11');
      }
    }
  }
}
.grid-date {
  display: flex;
  flex-direction: column;
  .end-date {
    margin-top: palette-space-level('10');
  }
}
.shape {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: #000;
  opacity: 0.8;
}
.popup-container {
  height: 100vh;
}
.shape,
.popup-image {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
}
.popup-image {
  max-width: 95%;
  max-height: 100%;
}
</style>
